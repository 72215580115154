<script setup>
import { inject } from 'vue';

const dayjs = inject('dayjs');

const props = defineProps({
    shift: Object,
});
</script>

<template>
    <div>
        <p class="mt-3 mb-3">{{ $t('Manual Input log') }}</p>
        <div v-if="shift.start_by_flexer">
            <div class="flex gap-4">
                <span class="font-bold w-18">{{ dayjs(shift.start_by_flexer).format('HH:mm') }}</span>
                <span>{{ $t('In') }}</span>
            </div>

            <div class="flex gap-4">
                <span class="font-bold w-18">{{ dayjs(shift.end_by_flexer).format('HH:mm') }}</span>
                <span>{{ $t('Out') }}</span>
            </div>

            <div class="flex gap-4">
                <span class="font-bold w-18">{{ Math.floor(shift.break_by_flexer / 3600) }} min</span>
                <span>{{ $t('Paused') }}</span>
            </div>
        </div>
        <div v-else>
            <p>{{ $t('No manual input log') }}</p>
        </div>
    </div>
</template>
